body {
  font-family: 'Roboto', sans-serif
}

#root {
  display: -webkit-flex;
  display: flex;
}

.content {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  margin: 1em;
}

.paper {
  padding: 1em;
  margin: auto;
  margin-bottom: 5em;
}

.pointer:hover {
  cursor: pointer;
}

a {
  color: #FFFFFF;
  text-decoration: none;
}

.tableHeader {
  background-color: cornsilk;
}

th {
  color: black !important;
}

